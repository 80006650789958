import React from 'react';

import { withFormik } from 'formik';
import { connect } from 'react-redux';
import { Button, Form, Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';

import Actions from 'src/state/root-actions';

import { hasAllValues, FORM_MESSAGES, getErrorWithBkgMessage } from 'src/utils/form-utils';
import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';

import * as authStyles from 'src/styles/auth.module.scss';

const PasswordForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
}) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const toggleShowPassword = () => setShowPassword((showPasswordState) => !showPasswordState);

    const { widths } = SUI_GRID_CENTERED;

    const togglePassword = () => (
        <span className={authStyles.togglePassword} onClick={toggleShowPassword}>
            Show
        </span>
    );

    return (
        <Form onSubmit={handleSubmit}>
            <Grid container centered>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                    >
                        <input
                            type="hidden"
                            id="login-email"
                            name="email"
                            value={values.email}
                            aria-label="Email (hidden form input)"
                        />
                        <Form.Field>
                            <Form.Input
                                className={`${authStyles.passwordIconButton} ${authStyles.input}`}
                                icon={togglePassword}
                                id="login-password"
                                name="password"
                                type={showPassword ? 'text' : 'password'}
                                placeholder="Password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                error={
                                    errors.password && touched.password
                                        ? getErrorWithBkgMessage(errors.password)
                                        : false
                                }
                            />
                        </Form.Field>
                        {errors?.general && getErrorWithBkgMessage(errors.general)}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                    >
                        <Button
                            type="submit"
                            disabled={!hasAllValues(values) || !isEmpty(errors)}
                            loading={isSubmitting}
                            className={authStyles.submitButton}
                            primary
                            fluid
                        >
                            Log In
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Form>
    );
};

const handleSubmit = (formValues, { props, setSubmitting, setFieldError }) => {
    const onFailure = (message) => setFieldError('general', message);
    const onFulfill = () => setSubmitting(false);
    window.localStorage.removeItem('marianaTekSubsEmail')
    props.dispatch(
        Actions.auth.login.trigger({
            ...formValues,
            onFailure,
            onFulfill,
        })
    );
};

const validate = (values) => {
    const error = {};

    if (!values.password) {
        error.password = FORM_MESSAGES.requiredField;
    }

    return error;
};

const FormikPasswordForm = withFormik({
    handleSubmit,
    validate,
    mapPropsToValues: (props) => ({ email: props.email, password: '' }),
})(PasswordForm);

export default connect()(FormikPasswordForm);
