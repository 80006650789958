import React, { useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import { Form, Grid } from 'semantic-ui-react';
import { isEmpty } from 'lodash';
import { WindowLocation } from '@reach/router';

import SEO from 'src/components/SEO';
import withLocation from 'src/components/withLocation';
import PasswordForm from 'src/components/auth/PasswordForm';
import { getErrorWithBkgMessage } from 'src/utils/form-utils';

import { SUI_GRID_CENTERED } from 'src/utils/semantic-utils';

import { useBreakpoints, Directions, Breakpoints } from 'src/utils/breakpoint-utils';
import * as authStyles from 'src/styles/auth.module.scss';

import Typography from '@mui/material/Typography';

interface LoginPasswordPageProps {
    location: WindowLocation;
}

const LoginPasswordPage = ({ location: { state } }: LoginPasswordPageProps) => {
    const isMobile = useBreakpoints(Breakpoints.lg, Directions.down);
    const isExternalProvider = state?.status === 'EXTERNAL_PROVIDER';
    const externalProviderMsg = `You previously registered with ${state?.provider}. Please use
    ${state?.provider} to login.`;

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (!state || isEmpty(state?.email)) {
            navigate('/continue');
        }
    }, []);

    if (!state || isEmpty(state?.email)) {
        return null;
    }

    const { widths } = SUI_GRID_CENTERED;

    return (
        <div className={authStyles.authWrapper}>
            <SEO title="Pvolve Login" />
            <Grid container centered>
                <Grid.Row>
                    <Grid.Column
                        mobile={widths.mobile}
                        tablet={widths.tablet}
                        computer={widths.computer}
                    >
                        <Typography
                            className={`${authStyles.welcomeMessage} text-align--center`}
                            variant="h5"
                        >
                            {state?.firstName
                                ? `Welcome back, ${state?.firstName}!`
                                : 'Welcome back!'}
                        </Typography>
                        {isMobile && (
                            <Typography
                                variant="body2"
                                className={`${authStyles.loginMessage} text-align--center`}
                            >
                                Log in to access your account settings and manage your membership.
                            </Typography>
                        )}
                        <Form.Field>
                            <Form.Input
                                type="email"
                                value={state?.email}
                                className={`${authStyles.input} ${authStyles.inputEmail}`}
                                disabled
                            />
                        </Form.Field>
                        {isExternalProvider && (
                            <div className={authStyles.errorMessage}>
                                {getErrorWithBkgMessage(externalProviderMsg)}
                            </div>
                        )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <PasswordForm email={state?.email} redirectUrl={state?.redirectUrl} />
            <Typography variant="body2" className={authStyles.forgotPassword}>
                <Link to="/loginHelp" state={{ email: state?.email }}>
                    Forgot password?
                </Link>
            </Typography>
        </div>
    );
};

export default withLocation(LoginPasswordPage);
